.service {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p, h4 {
      text-align: center;
    }
  }

  &-image{
    width: 150px;

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__content {
    flex: 1;

    &-description {
      color: lighten($dark-gray, 10);
      line-height: 1.7;
    }
  }

}
