.home-page {
  padding-top: 4rem;

  .background-cover {
    position: absolute;
  }

  .main-title {
    max-width: 450px;
    letter-spacing: 1px;
    padding-bottom: 1rem;
  }

  .cover {
    margin-top: -8rem;
    min-height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  
  .company-presentation {
    position: relative;

    .text-description {
      line-height: 1.6;
      font-weight: 300;
      font-size: 1.2rem;
    }

    .diagonal-bg-top {
      position: absolute;
      top: 0;
      width: 100%;
      clip-path: polygon(0% 0%, 4000px 40%, 100% 100%, 0% 100%);
      background: $secondary;
      height: 100%;
    }

    .about-word {
      font-size: 160px;
      opacity: 0.05;
      margin-top: -50px;
      margin-left: -145px;
      font-weight: 800;
      letter-spacing: 0.2px;
    }

    @include media-breakpoint-down(lg) {
      .about-word {
        font-size: 150px;
        margin-left: -120px;
        margin-top: -35px;
      }

      .text-description {
        margin-bottom: 3rem;
      }
    }

    @include media-breakpoint-down(sm) {
      .about-word {
        font-size: 100px;
        margin-left: -60px;
        margin-top: -15px;
      }
    }

    .code-editor {
      background: $primary;
      width: 100%;
      height: 14rem;
      border-radius: 11px 11px 11px 11px;
      box-shadow: $box-shadow-primary;
    }

    .editor {
      background: #f9f9f9;
      margin-bottom: -110px;
      border-radius: 11px 11px 11px 11px;
      box-shadow: $box-shadow-primary;

      &__status {
        height: 3rem;
      }

      img {
        border-radius: 0 0 11px 11px;
        object-fit: contain;
        transition: 0.7s all;
        transform: scale(0);
        transition-delay: 3.5s;
        opacity: 0;
      }

      .img-transition {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  .services-word {
    font-size: 160px;
    opacity: 0.05;
    font-weight: 800;
    letter-spacing: 0.2px;
    color: $secondary;
  }

  .o-word {
    font-size: 700px;
    opacity: 0.05;
    font-weight: 550;
    letter-spacing: 0.2px;
    color: $secondary;
    margin-top: -700px;
    margin-left: -180px;
  }

  @include media-breakpoint-down(lg) {
    .services-word {
      font-size: 150px;
    }
  }
  
  @include media-breakpoint-down(sm) {
    .main-title {
      font-size: 1.8rem;
      letter-spacing: .5px;
      line-height: 1.2;
    }
    .services-word {
      font-size: 100px;
      margin-bottom: -100px;
    }
    .o-word {
      font-size: 580px;
      margin-top: -550px;
      margin-bottom: 100px;
    }
  }

  .title-feature {
    font-size: $font-size-title-feature;
  }

  .text-description-feature {
    color: $dark-gray;
    font-size: 1.5rem;
  }
  
  .main-services {
    margin-top: -480px;


    .card-services {
      height: 90px;
      object-fit: contain;
      margin-top: 2rem;
    }

    .btn-link {
      color: white;
    }

    .card:hover {
      box-shadow: $box-shadow-primary;

      .btn-link {
        color: $tertiary
      }
    }

  }
  
  @include media-breakpoint-down(sm) {
    .main-services {
      margin-top: -400px;
    }
    .text-description-feature {
      font-size: 1.3rem;
    }
  }

  .main-projects {
    position: relative;

    .diagonal-bg-bottom {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      clip-path: polygon(0% 0%, 100% 0%, 4000px 100%, 0% 65%);
      background: $primary;
    }

    .project-background {
      background: white;
      opacity: 0.05;
      height: 50px;
      border-radius: 26px 26px 26px 26px;
    }

    .project-background1 {
      width: 50px;
      margin-top: -20px;
      margin-left: -50px;
    }

    .project-background2 {
      width: 160px;
      margin-top: -20px;
      margin-left: 35px;
    }

    .project-background3 {
      width: 200px;
      margin-left: -50px;
    }

    .project-background4 {
      width: 100px;
      margin-left: -35px;
    }

    .project-background5 {
      width: 70px;
      margin-left: 40px;
    }

    .mainproject-tittle {
      opacity: 50%;
      margin-top: -18px;
      font-size: 25px;
    }

    .mainproject-description {
      line-height: 1.5rem;
    }

    @include media-breakpoint-down(sm) {
      .content-projects {
        padding-bottom: 0px;
      }
      .diagonal-bg-bottom {
        height: 160%;
      }
    }

    @include media-breakpoint-only(md) {
      .content-projects {
        padding-bottom: 150px;
      }
      .diagonal-bg-bottom {
        height: 100%;
      }
    }

    .img-project1 {
      width: 900px;
      box-shadow: $box-shadow-primary;
      border-radius: 11px 11px 11px 11px;
    }

    .img-project {
      margin-bottom: -152px;
    }
  }

  .small-circle {
    background-color: transparentize($secondary, 0.90);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-top: -300px;
    position: absolute;
  }

  .main-stack {
    z-index: 1;

    .img-stack {
      height: 15vmax;
      max-height: 10rem;
      margin-left: 9rem;
      position: absolute;
    }

    .img-stack1 {
      top: -9rem;
      left: 7rem;
    }

    .img-stack2 {
      left: 35rem;
      top: -11rem;
    }

    .img-stack3 {
      top: 14rem;
    }

    .img-stack4 {
      left: 25rem;
      top: 24rem;
    }

    .img-stack5 {
      left: 45rem;
      top: 8rem;
      left: 42rem;
    }

    @include media-breakpoint-only(lg) {
      .img-stack1 {
        top: -9rem;
        left: 3rem;
      }

      .img-stack2 {
        left: 31rem;
        top: -11rem;
      }

      .img-stack3 {
        top: 14rem;
        left: -4rem;
      }

      .img-stack4 {
        left: 18rem;
        top: 24rem;
      }

      .img-stack5 {
        left: 38rem;
        top: 8rem;
      }
    }

    @include media-breakpoint-only(md) {

      .img-stack1 {
        top: -9rem;
        left: -6rem;
      }

      .img-stack2 {
        left: 20rem;
        top: -11rem;
      }

      .img-stack3 {
        top: 14rem;
        left: -10rem;
      }

      .img-stack4 {
        left: 10rem;
        top: 24rem;
      }

      .img-stack5 {
        left: 28rem;
        top: 8rem;
      }
    }

    @include media-breakpoint-only(sm) {
      .img-stack1 {
        left: -6rem;
        top: -2rem;
      }

      .img-stack2 {
        left: 13rem;
        top: -5rem;
      }

      .img-stack3 {
        top: 14rem;
        left: -10rem;
      }

      .img-stack4 {
        left: 7rem;
        top: 24rem;
      }

      .img-stack5 {
        left: 20rem;
        top: 11rem;
      }
    }

    @include media-breakpoint-only(xs) {
      .img-stack {
        height: 4rem;
        margin: 0;
      }
      .img-stack1 {
        left: 1rem;
        top: 0;
      }

      .img-stack2 {
        left: 70%;
        top: -1rem;
      }

      .img-stack3 {
        left: 1rem;
        top: 50vw;
      }

      .img-stack4 {
        left: 40%;
        top: 70vw;
      }

      .img-stack5 {
        left: 75%;
        top: 55vw;
      }
    }
  }

  .circle-background {
    opacity: 0.05;
    margin-top: -870px;
    z-index: -1;
  }

  @include media-breakpoint-only(md) {
    .circle-background {
      margin-top: -61rem;
    }
    .services-word {
      font-size: 100px;
    }
    .o-word {
      font-size: 600px;
      margin-left: -230px;
    }
  }

  @include media-breakpoint-only(sm) {
    .img-circle {
      width: 550px;
      margin-top: 2rem;
    }
  }

  @include media-breakpoint-only(xs) {
    .img-circle {
      width: 90%;
      margin-top: 90px;
    }
    .services-word {
      font-size: 55px;
    }
    .o-word {
      font-size: 500px;
      margin-top: -450px;
    }
    .circle-background {
      margin-top: -798px;
    }
  }

  .circle-md {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: $secondary-opacity;
  }

  .circle-md1 {
    margin-top: -600px;
    margin-left: 90px;
  }

  .circle-md2 {
    margin-top: 200px;
    margin-left: 860px;
    margin-bottom: -45px;
  }



  .circle-clients {
    transform: translate(-10%, -98%);
    z-index: -1;
  }

  @include media-breakpoint-only(sm) {
    .circle-clients1 {
      margin-left: 200px;
    }
    .circle-md2 {
      margin-left: 350px;
    }
  }

  @include media-breakpoint-only(xs) {
    .circle-clients1 {
      margin-left: 100px;
    }
    .circle-md2 {
      margin-left: 250px;
    }
  }

  @include media-breakpoint-only(md) {
    .circle-md2 {
      margin-left: 500px;
    }
    .circle-md1 {
      margin-left: 30px;
    }
  }

  @include media-breakpoint-only(lg) {
    .circle-md2 {
      margin-left: 700px;
    }
  }
}

.css-typing {
  p {
      padding: 0;
      border-right: .15em solid white;
      white-space: nowrap;
      overflow: hidden;
  }
}

.css-typing p:nth-child(1) {
  width: 6.5rem;
  animation: type 1.5s steps(13, end);
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(2) {
  width: 10rem;
  opacity: 0;
  animation: type2 1.5s steps(13, end), blink .5s step-end infinite alternate;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .15em solid white;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: transparent;
  }
}
