.contact-page {
  .bg-contact {
    background: $primary;
  }

  .contact {
    color: $secondary;
    opacity: 0.05;
    font-size: 200px;
    font-weight: 700;
  }

  @include media-breakpoint-down(lg) {
    .contact {
      font-size: 100px;
      margin-bottom: 50px;
    }
  }
  @include media-breakpoint-only(xs) {
    .contact {
      font-size: 70px;
      margin-bottom: 55px;
    }
  }

  .inputs, textarea {
    border: none;
    width: 90%;
    outline: none;
  }

  table, tr {
    border: 1px solid #dcdcdc;
    margin-bottom: 1rem;
  }

  td {
    padding-top: 15px;
    padding-left: 10px;
    width: 400px;
  }

  .contact-background {
    background: white;
    opacity: 0.05;
    height: 50px;
    border-radius: 26px 26px 26px 26px;
  }

  .contact-background1 {
    width: 50px;
    margin-top: -20px;
    margin-left: -50px;
  }

  .contact-background2 {
    width: 160px;
    margin-top: -20px;
    margin-left: 35px;
  }

  .contact-background3 {
    width: 200px;
    margin-left: -50px;
  }

  .contact-background4 {
    width: 100px;
    margin-left: -35px;
  }

  .contact-background5 {
    width: 70px;
    margin-left: 40px;
  }

  .circle-background {
    font-size: 800px;
    margin-left: -560px;
    margin-top: -700px;
    margin-bottom: -300px;
  }
}
