.design {
  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    // Cells
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          padding: 1rem;
          vertical-align: top;
          border: 1px solid #dcdcdc;

          > p:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    // Prevent breaking of code (e.g., Grunt tasks list)
    td:first-child > code {
      white-space: nowrap;
    }
  }
}
