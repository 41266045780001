.project-presentation {
  .hero {
    margin-top: 4rem;
    position: relative;
    z-index: 9;
    height: 500px;

    &::before {
      content: "";
      position: absolute;
      bottom: -100px;
      width: 100%;
      height: 100px;
      background-color: $secondary;
      clip-path: polygon(100% 0%, 0 0, 0 100%);
      z-index: -2;
    }
    @include media-breakpoint-down(sm) {
      &::before {
        clip-path: polygon(200% 0%, 0 0, 0 100%);
      }
    }
  }

  .phone {
    width: 285px;
    background-color: #ffffff;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    position: relative;
    box-shadow: 0 0 8px 6px hsla(202, 100%, 17%, 0.129);

    &::after {
      content: "";
      width: 8px;
      border-radius: 4px;
      height: 80px;
      background-color: #fff6f6;
      position: absolute;
      right: -4px;
      top: 80px;
    }

    &__screen {
      border: 1px solid hsla(240, 2%, 83%, 0.349);
      border-bottom: none;
      border-radius: 15px;
      width: 260px;
      height: calc(100% - 30px);
      margin-top: 13px;
      overflow: hidden;

      img {
        object-fit: cover;
        object-position: top;
        width: 100%;
        height: 100%;
      }
    }
  }

  .editor {
    background: #f9f9f9;
    border-radius: 11px 11px 0 0;
    box-shadow: $box-shadow-primary;
    z-index: 10;

    &__status {
      height: 3rem;
    }
  }

  .desktop_screen {
    border: 14px solid #f9f9f9;
    border-radius: 0 0 6px 6px;
    background-color: #f9f9f9;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 90%;
      height: 60%;
      background-color: rgba(22, 51, 97, 0.39);
      bottom: 0;
      left: 5%;
      filter: blur(60px);
      z-index: -1;
    }
  }

  .image-desktop {
    border-radius: 0 0 6px 6px;
  }

  .wrapper-description {
    p {
      line-height: 1.6;
      font-weight: 300;
      font-size: 1.2rem;
    }
  }

  .wrapper-content {
    text-align: center;

    h2 {
      margin-top: 3rem !important;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.6;
      font-weight: 300;
    }

    @include media-breakpoint-down(xs) {
      padding: 0 2rem;

      h2 {
        margin-top: 6rem !important;
        margin-bottom: 1rem;
      }
    }
  }

  .text-category {
    opacity: 50%;
    font-size: 25px;
  }

  .container-buttons {
    width: 140px;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    div:focus {
      outline: none;
    }
  }

  .arrow {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $secondary;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .related-projects {
    z-index: 5;

    a {
      text-decoration: none;
      z-index: 6;
    }

    .box-portfolio {
      border-radius: 15px 15px 0px 0px;
      width: 22rem;
      height: 30rem;

      .portfolio-row {
        width: 70rem;

        &__desktop {
          position: absolute;
          z-index: 2;
          max-width: 72%;
          max-height: 20rem;
          top: 10rem;
          padding: 0.5rem;
        }

        &__mobile {
          position: absolute;
          top: 5rem;
          max-width: 20rem;
          max-height: 19rem;
        }
      }

      &__mackbook {
        padding-top: 4.5rem;
        z-index: 1;
        max-width: 92%;
        max-height: 25rem;
      }

      &__iphone {
        max-width: 25rem;
        max-height: 25rem;
      }

      &__content {
        height: 5rem;
        border-radius: 0 0 10px 10px;
        width: 22rem;
        text-decoration: none;
        background-color: #fff;
      }
    }

    @media screen and (max-width: 1700px) {
      .box-portfolio {
        width: 22rem;
        height: 25rem;

        .portfolio-row {
          &__desktop {
            position: absolute;
            z-index: 2;
            max-width: 72%;
            // max-height: 15rem;
            top: 6.5rem;
          }

          &__mobile {
            position: absolute;
            top: 4.5rem;
            max-width: 15rem;
            max-height: 15rem;
          }
        }

        &__mackbook {
          position: absolute;
          z-index: 1;
          max-width: 85%;
          // max-height: 20rem;
        }

        &__iphone {
          max-width: 20rem;
          max-height: 20rem;
        }

        &__content {
          width: 22rem;
        }
      }
    }

    @media screen and (max-width: 1400px) {
      .box-portfolio {
        width: 20rem;
        height: 20rem;

        .portfolio-row {
          &__desktop {
            top: 7.2rem;
            position: absolute;
            z-index: 2;
            max-width: 11rem;
            max-height: 11rem;
          }

          &__mobile {
            position: absolute;
            top: 4rem;
            max-width: 11rem;
            max-height: 11rem;
          }
        }

        &__mackbook {
          padding-top: 4rem;
          position: absolute;
          z-index: 1;
          max-width: 75%;
          max-height: 15rem;
        }

        &__iphone {
          max-width: 15rem;
          max-height: 15rem;
        }

        &__content {
          width: 20rem;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      .portfolio-row {
        width: 50rem;
      }
    }

    .box-portfolio:nth-child(n) {
      background: $red;
    }

    .box:nth-child(2n) {
      .box-portfolio {
        background: $aquamarine;
      }
    }

    .box:nth-child(3n) {
      .box-portfolio {
        background: $peach;
      }
    }

    .box:hover {
      .box-portfolio {
        &__content {
          box-shadow: $box-shadow-primary;
        }
      }
    }
  }
}
