.footer {
  .logo-footer {
    width: 95px;
  }

  &__background {
    z-index: -1;
    top: 0;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .footer-icons {
    a {
      font-size: 1.4rem;
      margin: 3px;
      background-color: transparentize($primary, 0.85);
      border-radius: 50%;

      .fa {
        width: 1.1rem;
      }
    }
  }

  p {
    color: $primary;
  }

  .nav-link {
    &:hover {
      color: $secondary;
    }
  }
}
