.call-to-action {
  z-index: 1;
  width: 70rem;
  background-color: $secondary;
  border-radius: 10px;

  a {
    min-width: 10rem;
    padding: .5rem 0 .5rem 0;
  }

  @include media-breakpoint-down(md) {
    border-radius: 0px;
  }
}
