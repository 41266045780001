.services {
  &__hero {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: -100px;
      width: 100%;
      height: 100px;
      background-color: $secondary;
      clip-path: polygon(100% 0%, 0 0, 100% 100%);
      z-index: -2;
    }

    @include media-breakpoint-down(sm) {
      &::before {
        clip-path: polygon(200% 0%, 0 0, 0 100%);
      }
    }
  }
  .services-background {
    color: lighten($secondary, 2);
    font-size: 140px;
    letter-spacing: -3px;
    font-weight: 800;
  }

  &__service {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 15px;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
  }

  .title {
    width: 25rem;
    margin-top: -6rem;
  }

  &__image{
    display: flex;
    max-width: 1140px;
    width: 100%;
    justify-content: center;
    margin: -40px auto 0;

    img {
      z-index: 1;
      margin-top: -4rem;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__feature{
    margin: 0 auto;
    max-width: 700px;
    width: 100%;
    padding: 10px 20px;

    &-description {
      color: lighten($dark-gray, 10);
      font-size: 1.4rem;
    }
  }

  .services-admin {
    position: relative;

    h1,
    h5 {
      color: white;
    }

    .img-services {
      width: 100%;
    }

    .img-services:hover {
      box-shadow: $box-shadow-primary;
    }

    .lines-background {
      background: rgba(255, 255, 255, 0.05);
      height: 44px;
      border-radius: 22px;
    }

    .line2 {
      width: 110px;
    }

    .line3 {
      width: 55px;
    }

    .line4 {
      width: 176px;
    }

    .line5 {
      width: 70px;
    }

    .line6 {
      width: 170px;
    }

    .lines-background-white {
      margin-top: -30px;
      margin-left: -120px;
    }
  }

  .services-admin:nth-child(2n) {
    .box-transparent {
      margin-top: -120px;
    }

    h1,
    h5 {
      color: $primary;
    }

    .lines-background {
      background-color: $secondary-opacity;
      z-index: -1;
    }
  }

  .circle-md {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: $secondary-opacity;
  }

  .circle-md1 {
    margin-top: -600px;
    margin-left: 90px;
  }

  .circle-md2 {
    margin-top: 200px;
    margin-left: 860px;
    margin-bottom: -45px;
  }

  .circle-clients {
    transform: translate(-10%, -98%);
    z-index: -1;
  }

  .circle-clients1 {
    width: 40px;
    margin-bottom: -100px;
    margin-left: 600px;
  }

  @include media-breakpoint-up(md) {
    .img-services {
      margin-top: 181px;
    }
  }

  @include media-breakpoint-only(sm) {
    .services-background {
      font-size: 100px;
      margin-bottom: 40px;
    }

    .circle-clients1 {
      margin-left: 200px;
    }

    .circle-md2 {
      margin-left: 350px;
    }
  }

  @include media-breakpoint-only(xs) {
    .services-background {
      font-size: 60px;
      margin-bottom: 70px;
    }

    .circle-clients1 {
      margin-left: 100px;
    }

    .circle-md2 {
      margin-left: 250px;
    }
  }

  @include media-breakpoint-only(md) {
    .services-background {
      font-size: 110px;
      margin-bottom: 35px;
    }

    .circle-md2 {
      margin-left: 500px;
    }

    .circle-md1 {
      margin-left: 30px;
    }
  }

  @include media-breakpoint-only(lg) {
    .circle-md2 {
      margin-left: 700px;
    }
  }
}
