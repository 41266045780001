.circle {
  margin: 5px;
  width: 17px;
  height: 17px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.diagonal-bg-bottom-right {
  position: absolute;
  top: 0;
  width: 100%;
  clip-path: polygon(0% 0%, 100% 0%, 4000px 56%, 0% 100%);
  background: $secondary;
  height: 100%;
}

.diagonal-bg-top {
  position: absolute;
  top: 0;
  width: 100%;
  clip-path: polygon(0% 0%, 100% 23%, 100% 100%, 0% 100%);
  background: $secondary;
  height: 100%;
}

.diagonal-bg-bottom-left {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 75%);
  background: $primary;
}
