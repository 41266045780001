.navbar {
  z-index: 10;
  position: fixed;
  width: 100%;
  background-color: white;
  top: 0;

  .menu-bar {
    display: none;
  }

  .logo {
    height: 5rem;
  }

  .nav-link {
    &:hover {
      color: $secondary;
    }
  }

  .language-selector {
    display: flex;
    justify-content: center;
    border: 0;
    outline: none;
    color: $primary;

    &:hover {
      color: $secondary;
    }
  }
}

@media screen and (max-width: 800px) {
  .navbar{
    padding: 0 3rem 0 3rem !important;
    .menu-bar {
      display: block;
      
      img {
        width: 2rem;
        height: 2rem;
      }
    }

    ul {
      z-index: 2;
      left: -100%;
      background-color: white;
      margin-top: 5rem;
      position: absolute;
      top: 0;
      display: block;
    }
  }
}

.navbar-portfolio {
  background-color: $secondary;

  .nav-link {
    color: white;
    font-weight: 300;

    &:hover {
      color: $primary;
    }
  }

  .language-selector {
    font-weight: 300;
    color: white;
    background-color: $secondary;

    &:hover {
      color: $primary;
    }
  }
}

.base-cover {
  position: absolute;
  z-index: -1;
  height: 80%;
  overflow: hidden;

  &__background {
    width: 100%;
    animation: cover 480s infinite;
    animation-timing-function: linear;
  }
}

@keyframes cover {
  0% {
    transform: rotate(0turn);
  }
  100% {
    transform: rotate(1turn);
  }
}
