.portfolio {
  .bg-portfolio {
    position: relative;

    .diagonal-portfolio {
      z-index: -1;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      clip-path: polygon(0% 0%, 100% 0%, 4000px 100%, 0% 65%);
      background: $secondary;
    }
  }

  .container-fluid {
    z-index: 1;
  }

  .word-bg {
    font-size: 125px;
    opacity: 0.05;
    font-weight: 750;
    margin-bottom: -76px;
  }

  .hover-secondary {
    &:hover {
      color: $secondary !important;
    }
  }

  .projects {
    margin-top: -300px;
  }

  .portfolio-item {
    &:hover {
      text-decoration: none;
    }
  }

  .box-portfolio {
    border-radius: 15px 15px 0px 0px;
    width: 22rem;
    height: 30rem;

    .portfolio-row {
      width: 70rem;
  
      &__desktop {
        position: absolute;
        z-index: 2;
        max-width: 72%;
        max-height: 20rem;
        top: 10rem;
        padding: .5rem;
      }

      &__mobile {
        position: absolute;
        top: 5rem;
        max-width: 20rem;
        max-height: 19rem;
      }
    }

    &__mackbook {
      padding-top: 4.5rem;
      z-index: 1;
      max-width: 92%;
      max-height: 25rem;
    }

    &__iphone {
      max-width: 25rem;
      max-height: 25rem;
    }

    &__content {
      height: 5rem;
      border-radius: 0 0 10px 10px;
      width: 22rem;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 1700px) {
    .box-portfolio {
      width: 22rem;
      height: 25rem;

      .portfolio-row {
    
        &__desktop {
          position: absolute;
          z-index: 2;
          max-width: 72%;
          // max-height: 15rem;
          top: 6.5rem;
        }

        &__mobile {
          position: absolute;
          top: 4.5rem;
          max-width: 15rem;
          max-height: 15rem;
        }
      }
  
      &__mackbook {
        position: absolute;
        z-index: 1;
        max-width: 85%;
        // max-height: 20rem;
      }

      &__iphone {
        max-width: 20rem;
        max-height: 20rem;
      }

      &__content {
        width: 22rem;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .box-portfolio {
      width: 20rem;
      height: 20rem;

      .portfolio-row {

        &__desktop {
          top: 7.2rem;
          position: absolute;
          z-index: 2;
          max-width: 11rem;
          max-height: 11rem;
        }

        &__mobile {
          position: absolute;
          top: 4rem;
          max-width: 11rem;
          max-height: 11rem;
        }
      }
  
      &__mackbook {
        padding-top: 4rem;
        position: absolute;
        z-index: 1;
        max-width: 75%;
        max-height: 15rem;
      }

      &__iphone {
        max-width: 15rem;
        max-height: 15rem;
      }

      &__content {
        width: 20rem;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .portfolio-row {
     width: 50rem;
    }
  }

  .box-portfolio:nth-child(n) {
    background: $red;
  }

  .box:nth-child(2n) {
    .box-portfolio {
      background: $aquamarine;
    }
  }

  .box:nth-child(3n) {
    .box-portfolio {
      background: $peach;
    }
  }

  @include media-breakpoint-only(xs) {
    .word-bg {
      font-size: 45px;
      margin-bottom: -15px;
    }
  }
  @include media-breakpoint-only(sm) {
    .word-bg {
      font-size: 85px;
      margin-bottom: -50px;
    }
  }
  @include media-breakpoint-only(md) {
    .word-bg {
      font-size: 115px;
      margin-bottom: -65px;
    }

  }

  .box:hover {
    .box-portfolio {
      &__content {
        box-shadow: $box-shadow-primary;
      }
    }
  }
}

