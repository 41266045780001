.clients {
  .logo-clients {
    max-width: 11.5em;
    max-height: 7rem;
    opacity: 0.7;
  }

  @media screen and (max-width: 770px) {
    .logo-clients {
      width: 4.5rem;
    }
  }
}
