.company {
  .about-us-background {
    color: $secondary;
    opacity: 0.05;
    font-size: 140px;
    letter-spacing: -3px;
    font-weight: 800;
  }

  &__wrapper-img {
    width: 80%;
    margin: 0 auto;
  }

  .img-company {
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
    border-radius: 14px;
  }

  .company-title {
    width: 35rem;
    margin-top: -6rem;
  }

  .what-we-offer {
    position: relative;

    &-description {
      font-size: 1.2rem;
      line-height: 1.6;
    }

    &-wrapper-video {
      max-height: 440px;
    }

    &-list{
      list-style: none;
      font-size: 1.3rem;
      line-height: 2;
    }
    .video {
      object-fit: cover;
      width: 100%;
      height: 500px;
      border-radius: 20px;
      object-position: right;
    }

    .lines-background {
      background: rgba(255, 255, 255, 0.05);
      height: 44px;
      border-radius: 22px;
    }

    .line2 {
      width: 110px;
    }

    .line3 {
      width: 55px;
    }

    .line4 {
      width: 176px;
    }

    .line5 {
      width: 70px;
    }

    .line6 {
      width: 170px;
    }

    .lines-background-white {
      margin-top: -30px;
      margin-left: -120px;
    }
  }

  .circle-company {
    margin-top: -500px;
    margin-left: -220px;
  }

  .lines-background {
    background: rgba(0, 125, 197, 0.05);
    height: 44px;
    border-radius: 22px;
  }

  .lines {
    margin-bottom: -270px;
    margin-right: -35px;
  }

  .line1 {
    width: 150px;
  }

  .line2 {
    width: 110px;
  }

  .line3 {
    width: 35px;
  }

  .line4 {
    width: 170px;
  }

  .line5 {
    width: 60px;
  }

  .line6 {
    width: 90px;
  }

  .about-us {
    &-description {
      line-height: 1.6;
      font-size: 1.2rem;
    }
    .title-opacity {
      color: $secondary;
      opacity: 0.5;
      font-size: 25px;
      font-weight: 650;
    }

    .img-about-us {
      object-fit: cover;
      width: 100%;
      height: 550px;
      border-radius: 20px;
    }
  }

  .circle-background {
    margin-top: -200px;
    margin-left: -100px;
    margin-bottom: -100px;
  }

  .team-background {
    color: $secondary;
    opacity: 0.05;
    font-size: 100px;
    letter-spacing: -3px;
    font-weight: 800;
    margin-bottom: -98px;
  }

  .circle-xs-img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  .our-team {

    .team-member:nth-child(3n - 1) {
      .circle-img {
        background-color: $primary-opacity !important;
      }
    }

    .img-member {
      height: 300px;
      position: relative;
      z-index: 1;
    }

    .circle-img {
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 65%;
      height: 220px;
      width: 220px;
      background-color: $secondary-opacity;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }

  .circle-md {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: $secondary-opacity;
  }

  .circle-md1 {
    margin-top: -600px;
    margin-left: 90px;
  }

  .circle-md2 {
    margin-top: 200px;
    margin-left: 860px;
    margin-bottom: -45px;
  }

  .circle-clients {
    transform: translate(-10%, -98%);
    z-index: -1;
  }

  .circle-clients1 {
    width: 40px;
    margin-bottom: -100px;
    margin-left: 600px;
  }

  @include media-breakpoint-only(sm) {
    .about-us-background {
      font-size: 100px;
      margin-bottom: 40px;
    }
    .company-title {
      font-size: 30px;
    }
    .circle-background {
      width: 220px;
      margin-left: -70px;
      margin-top: -150px;
    }
    .team-background {
      font-size: 100px;
      margin-bottom: -90px;
    }
    .circle-clients1 {
      margin-left: 200px;
    }
    .circle-md2 {
      margin-left: 350px;
    }
  }
  @include media-breakpoint-only(xs) {
    .about-us-background {
      font-size: 60px;
      margin-bottom: 70px;
    }
    .company-title {
      font-size: 25px;
    }
    .circle-background {
      margin-top: -180px;
      margin-left: -70px;
      width: 200px;
    }
    .team-background {
      font-size: 100px;
      margin-bottom: -75px;
      margin-top: 15px;
    }
    .circle-xs-img {
      margin-left: 10px;
    }
    .circle-clients1 {
      margin-left: 100px;
    }
    .circle-md2 {
      margin-left: 250px;
    }
  }
  @include media-breakpoint-only(md) {
    .about-us-background {
      font-size: 110px;
      margin-bottom: 35px;
    }
    .company-title {
      font-size: 35px;
    }
    .circle-md2 {
      margin-left: 500px;
    }
    .circle-md1 {
      margin-left: 30px;
    }
  }

  @include media-breakpoint-down(md) {
    .circle-company {
      width: 25%;
      margin-top: -300px;
      margin-left: -140px;
    }
    .circle-md1 {
      margin-left: 50px;
    }
  }
  @include media-breakpoint-only(lg) {
    .circle-md2 {
      margin-left: 700px;
    }
  }

  @media screen and (max-width: 800px) {
    .what-we-offer {
      .row {
        padding: 3rem !important;
      }
    }

    .about-us {
      .row {
        padding: 3rem !important;
      }
    }
  }
}
